import React, { useContext, ReactElement } from 'react';

import { faClock, faUser, faFire, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Time } from '../libs/time';

import { RecipeStats as RecipeStatsData } from '../libs/recipe-stats';

import { LocaleContext, getLocalizedValue } from '../components/locale-context';
import { getRecipeStatsLabels } from '../queries/get-recipe-stats-labels';

interface RecipeStatProps {
  prepduration?: Time,
  duration?: Time,
  servings?: number;
}

function RecipeStat ({ prepduration, duration, servings }: RecipeStatProps): ReactElement {
  const { locale } = useContext(LocaleContext);
  const labels = getRecipeStatsLabels();
  const localLabels = getLocalizedValue(labels, locale);

  let label = '';
  let icon: IconDefinition | null = null;
  let tooltip = '';
  if (duration) {
    label = duration.toString(locale);
    icon = RecipeStat.icons[RecipeStat.DURATION];
    tooltip = localLabels.recipeStatTooltipCookingTime;
  } else if (servings) {
    label = `${servings.toString()} ${localLabels.recipeStatServings}`;
    icon = RecipeStat.icons[RecipeStat.SERVING];
    tooltip = localLabels.recipeStatTooltipServings;
  } else if (prepduration) {
    label = prepduration.toString(locale);
    icon = RecipeStat.icons[RecipeStat.PREPARATION];
    tooltip = localLabels.recipeStatTooltipPreparationTime;
  }

  return (
    <span
      className = "w3-padding-small"
      style = {{ display: `inline-block` }}
      title = { tooltip }
    >
      {icon && <FontAwesomeIcon icon={icon} />}
        &nbsp;&nbsp;
      <span>{ label }&nbsp;</span>
    </span>
  );
}


RecipeStat.PREPARATION = 0;
RecipeStat.DURATION = 1;
RecipeStat.SERVING = 2;
const icons : {[key: number]: IconDefinition} = {};
RecipeStat.icons = icons;
RecipeStat.icons[RecipeStat.PREPARATION] = faClock;
RecipeStat.icons[RecipeStat.DURATION] = faFire;
RecipeStat.icons[RecipeStat.SERVING] = faUser;

interface RecipeStatsProps {
  stats: RecipeStatsData
}

class RecipeStats extends React.Component<RecipeStatsProps> {
  render () {
    const statsData: RecipeStatsData = Object.assign({}, this.props.stats);
    const stats = [];
    if (statsData.prepduration) {
      stats.push(<RecipeStat prepduration = {statsData.prepduration} key = { 'preparation' } />);
    }
    if (statsData.duration) {
      stats.push(<RecipeStat duration = {statsData.duration} key = { 'duration' } />);
    }
    if (statsData.servings) {
      stats.push(<RecipeStat servings = {statsData.servings} key = { 'servings' } />);
    }

    if (stats.length === 0) {
      return null;
    }

    return (
      <div className = "w3-text-gray w3-small">
        { stats }
      </div>
    );
  }
}

export default RecipeStats;

import { Time } from "./time";

export class RecipeStats {
  public prepduration: Time | null;
  public duration: Time | null;
  public servings: number | null;

  constructor (prepduration: Time | null, duration: Time | null = null, servings: number | null = null) {
    this.prepduration = prepduration;
    this.duration = duration;
    this.servings = servings;
  }
}

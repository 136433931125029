import React, { ReactElement } from 'react';

import { GatsbyImage as Img } from 'gatsby-plugin-image';

import { LocaleContext, getLocalizedValue, LocalizedLabels } from '../components/locale-context';
import { getWineMatches, WineMatchInfo } from '../queries/get-wine-matches';
import { WineTitleInfo } from '../libs/wine-title';

interface WineMatchProps {
  wine: WineMatchInfo
}

class WineMatch extends React.Component<WineMatchProps> {
  render () {
    const { wine } = this.props;
    const wineTitle = new WineTitleInfo(wine.title);
    return (
      <span style = {{
        display: 'inline-block',
        verticalAlign: 'middle'
      }}>
        &nbsp;
        <Img
          image = { wine.icon.image }
          alt = { wineTitle.longLabel }
          title = { wineTitle.longLabel }
          style = {{
            verticalAlign: 'middle'
          }}
        />
        &nbsp;
        <span>{ wineTitle.getDynamicLabel() }</span>
      </span>
    );
  }
}

function generateIconsAndTooltip (wineIds: string[], wineList: WineMatchInfo[], labels: LocalizedLabels) {
  const icons: ReactElement[] = [];
  const tooltipParts: string[] = [];
  wineList.forEach(wine => {
    // icons
    if (!wineIds.some(wineId => wineId === wine.id)) {
      return;
    }
    icons.push(<WineMatch wine = { wine } key = { 'match' + wine.id } />);

    // tooltip
    tooltipParts.push(wine.title);
  });

  let tooltip = labels.sectionRecipesPairsWell;
  tooltip = tooltip.replace('{0}', tooltipParts.join(', '));

  return {
    icons: icons,
    tooltip: tooltip
  };
}

interface WineMatchesProps {
  wineIds: string[]
}

function WineMatches ({ wineIds }: WineMatchesProps): ReactElement {
  const wineList = getWineMatches();

  return <LocaleContext.Consumer>
    {
      ({ locale }) => {
        const localizedData = getLocalizedValue(wineList, locale);
        const { icons, tooltip } =
          generateIconsAndTooltip(wineIds, localizedData.list, localizedData.labels);
        return (
          <div className = "w3-small" title = { tooltip } >
            { icons }
          </div>
        );
      }
    }
  </LocaleContext.Consumer>;
}

export default WineMatches;

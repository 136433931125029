import React, { ReactElement } from 'react';
import { Link, graphql } from 'gatsby';
import Layout from '../components/layout';

import { getSrc, IGatsbyImageData } from 'gatsby-plugin-image';
import ImageTitled from '../components/image-titled';

import { LinkData } from "@chweb/commonlib";
import { Time } from '../libs/time';
import {
  RecipeStats as RecipeStatsData 
} from '../libs/recipe-stats';
import { dropUrlPrefix } from '../libs/url';

import { InterractiveSection } from "@chweb/commonui";
import WithMargins from '../components/with-margins';

import WineMatches from '../components/wine-matches-icons';
import RecipeStats from '../components/recipe-stats';

import {
  parseLabelsFromNodesLocalized as parseLabels,
  getLocalizedValue
} from '../components/locale-context';
import { pageInfoByLocale } from '../queries/page-info-helpers';
import { localizeUrl } from '../libs/locale';
import { PageSEO } from '../libs/seo';
import { LocationInfo } from '../libs/location';
import { AllLocalesLabels } from '../queries/fragment-all-locales-labels';
import { PageInfoData } from '../queries/fragment-page-info';

interface RecipeProps {
  title: string,
  titleimage: IGatsbyImageData,
  recipeLink: string,
  wineMatches: string[],
  recipeStats: RecipeStatsData,
  content: string
}

function Recipe ({ title, titleimage, recipeLink, wineMatches, recipeStats, content }: RecipeProps): ReactElement {
  let wineIds: string[] = [];
  if (wineMatches && Array.isArray(wineMatches)) {
    wineIds = wineMatches;
  }

  return (
    <>
      <h2>
        <Link
          className = { 'w3-xlarge'.concat(' cpcolor-text-brown')}
          to = { recipeLink }
        >
          { title }
        </Link>
      </h2>
      <div className = "w3-right w3-mobile" style = {{ width: '400px' }}>
        <WithMargins margin="5%">
          <ImageTitled image = { titleimage } description = { title } />
        </WithMargins>
      </div>
      <WineMatches wineIds = { wineIds }/>
      { recipeStats &&  <RecipeStats stats = { recipeStats } /> }
      <div dangerouslySetInnerHTML = {{ __html: content }}></div>
    </>
  );
}

interface RecipeTemplateProps {
  data: RecipeTemplateData,
  pageContext: { slug: string, locale: string},
  location: LocationInfo
}

export default function RecipeTemplate ({ data, pageContext, location }: RecipeTemplateProps): ReactElement {
  const { locale } = pageContext;
  const labels = parseLabels(data.labels.nodes, locale);
  const url = localizeUrl('/recipes/', locale);
  const linkData = new LinkData(url, labels.sectionRecipesAllLink);
  const localPageInfo = getLocalizedValue(pageInfoByLocale(data.page), locale);

  const { recipe, localRecipe } = data;

  // this page should be indexed and shared as article
  const seo = PageSEO.fromLocation(location);
  seo.isArticle = true;
  seo.description = localRecipe.excerpt;

  const recipeImage = recipe.frontmatter.titleimage.childImageSharp.gatsbyImageData;
  seo.image = dropUrlPrefix(getSrc(recipeImage) as string, '/static/');

  return (
    <Layout locale={pageContext.locale} location={location} title = { localRecipe.frontmatter.title } seo = { seo }>
      <WithMargins margin="5%">
        <InterractiveSection
          title = { localPageInfo.title }
          bottomLink = { linkData }
          topLink = { linkData }
        >
          <Recipe
            title = { localRecipe.frontmatter.title }
            titleimage = { recipeImage }
            wineMatches = { recipe.frontmatter.pairing }
            recipeStats = {
              new RecipeStatsData(
                recipe.frontmatter.info.prepmins ? new Time(0, recipe.frontmatter.info.prepmins) : null,
                recipe.frontmatter.info.mins ? new Time(0, recipe.frontmatter.info.mins) : null,
                recipe.frontmatter.info.servings)
            }
            recipeLink = { localizeUrl(`/recipes${localRecipe.fields.slug}`) }
            content = { localRecipe.html }
          />
        </InterractiveSection>
      </WithMargins>
    </Layout>
  );
}

interface RecipeTemplateData {
  recipe: {
    frontmatter: {
      titleimage: {
        childImageSharp: {
          gatsbyImageData: IGatsbyImageData
        }
      },
      pairing: string[],
      info: {
        prepmins: number,
        mins: number,
        servings: number
      }
    }
  },
  localRecipe: {
    html: string,
    frontmatter: {
      title: string
    },
    excerpt: string,
    fields: {
      slug: string
    }
  },
  labels: {
    nodes: AllLocalesLabels[]
  },
  page: PageInfoData
}

export const mediaQuery = graphql`
query(
  $slug: String!, $parentSlug: String!
) {
    recipe: markdownRemark(fields: { slug: { eq: $parentSlug }}) {
      frontmatter {
        titleimage {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 800)
          }
        }
        pairing
        info {
          prepmins
          mins
          servings
        }
      }
    }
    localRecipe: markdownRemark(fields: { slug: { eq: $slug } }) {
        html
        frontmatter {
          title
        }
        excerpt(pruneLength: 250)
        fields {
          slug
        }
    }
    labels: allLabelsTranslatedYaml(filter: {name: {in: [
        "sectionRecipesAllLink"
    ]}}) {
      nodes {
        ...AllLocalesLabels
      }
    }
    page: pageIndexYaml(yamlId : {eq: "recipes"}) {
      ...PageInfo
    }
}`;

// this class does local localization and support a limited number of languages
const TimeType = {
  SEC: 0,
  MIN: 1,
  HOUR: 2
} as const;
type TimeType = typeof TimeType[keyof typeof TimeType];

export class Time {
  public static readonly LOCALES: { [key: string]: { [key: number]: string } } = {
    en: {},
    fr: {},
    ru: {},
    zh: {}
  };

  public hours: number;
  public minutes: number;
  public seconds: number;

  constructor (hours = 0, minutes = 0, seconds = 0) {
    this.hours = hours;
    this.minutes = minutes;
    this.seconds = seconds;
  }

  public toString (locale = 'en'): string {
    let localeLabels = null;
    if (locale in Time.LOCALES) {
      localeLabels = Time.LOCALES[locale];
    } else {
      localeLabels = Time.LOCALES['en'];
    }

    let value = '';
    if (this.hours) {
      value += this.hours.toString() + localeLabels[TimeType.HOUR];
    }
    if (this.minutes) {
      if (value !== '') {
        value += ' ';
      }
      value += this.minutes.toString() + localeLabels[TimeType.MIN];
    }
    if (this.seconds) {
      if (value !== '') {
        value += ' ';
      }
      value += this.seconds.toString() + localeLabels[TimeType.SEC];
    } else {
      if (value === '') {
        value = '0 ' + localeLabels[TimeType.SEC];
      }
    }
    return value;
  }
}

// English
Time.LOCALES.en[TimeType.SEC] = 'sec';
Time.LOCALES.en[TimeType.MIN] = 'min';
Time.LOCALES.en[TimeType.HOUR] = 'h';

// French
Time.LOCALES.fr[TimeType.SEC] = 'sec';
Time.LOCALES.fr[TimeType.MIN] = 'min';
Time.LOCALES.fr[TimeType.HOUR] = 'h';

// Russian
Time.LOCALES.ru[TimeType.SEC] = 'сек';
Time.LOCALES.ru[TimeType.MIN] = 'мин';
Time.LOCALES.ru[TimeType.HOUR] = 'ч';

// Chinese
Time.LOCALES.zh[TimeType.SEC] = '秒';
Time.LOCALES.zh[TimeType.MIN] = '分';
Time.LOCALES.zh[TimeType.HOUR] = '时';


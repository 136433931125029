import React, { ReactElement, CSSProperties } from 'react';
import PubSub from 'pubsub-js';

import { GatsbyImage as Img, IGatsbyImageData } from 'gatsby-plugin-image';

import {
  ImagePopupEvent,
  ImageWithDescription,
  ContentWithDescription
} from './image-popup';

function handleClick (imageInfo: ImageWithDescription | ContentWithDescription): void {
  PubSub.publishSync(ImagePopupEvent, imageInfo);
}

type TitleImageProps = {
  image: IGatsbyImageData;
  content?: ReactElement;
  description: string;
  style?: CSSProperties;
  imgStyle?: CSSProperties;
};

function TitledImage({ image, content, description, style, imgStyle }: TitleImageProps): ReactElement {
  const extraStyle = style || { width: '100%', cursor: 'pointer' };
  return (
    <div
      className='w3-container'
      onClick={() =>
        handleClick(
          image
            ? new ImageWithDescription(description, image)
            : new ContentWithDescription(description, content || (<></>))
        )
      }>

      {' '}
      {content || (
        <Img image = {image} imgStyle = {imgStyle} style = { extraStyle }
          title={description}
          alt = {description}
          className='w3-hover-opacity w3-slow-hover'
        />
      )}{' '}
    </div>
  );
}

export default TitledImage;

import { useStaticQuery, graphql } from 'gatsby';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import { LocalizedLabels } from '../components/locale-context';

import { localizeUrl } from '../libs/locale';
import { getWineLocalTitle } from '../queries/page-wine-helpers';
import { AllLocalesLabels } from './fragment-all-locales-labels';
import { WineListData } from './fragment-wine-list';

interface WineMatchesData {
  labels: {
    nodes: AllLocalesLabels[]
  },
  list: {
    frontmatter: {
      wines: WineListData[]
    }
  }
}

export type WineMatchInfo = {
  id: string;
  title: string;
  icon: {
    image: IGatsbyImageData
  },
  url: string
};

export type WineMatches = {
  [key: string]: {
    labels: LocalizedLabels,
    list: WineMatchInfo[],
    byId: { [key: string]: WineMatchInfo }
  }
};

export const getWineMatches = (): WineMatches => {
  const { list, labels }: WineMatchesData = useStaticQuery(
    // $includeBottle, $includeDescriptions variables below are used by wineList fragment
    // see implementation
    graphql`
      query ($includeBottle: Boolean = false, $includeDescriptions: Boolean = false) {
        labels: allLabelsTranslatedYaml(
          filter: {name: {in: ["sectionRecipesPairsWell"]}}) {
            nodes {
              ...AllLocalesLabels
            }
          }
        list: markdownRemark(fields: {slug: {eq: "/wines-list/"}}) {
          frontmatter {
            wines {
              ...wineList
            }
          }
        }
      }`);

  const wines = list.frontmatter.wines;

  const byLocale: WineMatches = {};
  const ensureLocaleExists = (locale: string) => {
    if (!byLocale.hasOwnProperty(locale)) {
      byLocale[locale] = {
        labels: {},
        list: [],
        byId: {}
      };
    }
  };

  wines.forEach(w => {
    w.locales.forEach(l => {
      ensureLocaleExists(l.locale);
      const wineInfo: WineMatchInfo = {
        id: w.id,
        title: getWineLocalTitle(l.file.childMarkdownRemark.frontmatter.pageId.pageTitle, l.locale),
        icon: {
          image: w.icon.childImageSharp.gatsbyImageData
        },
        url: localizeUrl(l.file.childMarkdownRemark.fields.slug)
      };

      const localized = byLocale[l.locale];
      localized.list.push(wineInfo);
      localized.byId[wineInfo.id] = wineInfo;
    });
  });

  labels.nodes.forEach(label => {
    Object.keys(label.locales).forEach(loc => {
      const labelText = label.locales[loc];
      byLocale[loc].labels[label.name] = labelText;
    });
  });

  return byLocale;
}
